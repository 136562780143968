import axios from "axios";

export async function uploadFileToFolder(file, folderPath) {
  const formData = new FormData();
  formData.append('file', file.inputFiles[0]);
  formData.append('filePath', file.inputValue);
  formData.append('folderPath', folderPath);

  try {
    const response = await axios.post('/api/hubspot/file', formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data.url;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
}