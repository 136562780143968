import { componentMapper } from '@data-driven-forms/mui-component-mapper';
import { FormRenderer, FormTemplateRenderProps, Schema, componentTypes, validatorTypes } from '@data-driven-forms/react-form-renderer';
import { Alert, AlertTitle, Box, Snackbar, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import newsletterImage from '@/public/assets/images/newsletter.svg';
import NewsletterFormTemplate from '@components/Common/Form/Template/NewsletterFormTemplate';
import Row from '@components/Modules/Utils/Row/Row';
import { DrupalNode } from "next-drupal";
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { NextRouter, useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import Loader from '@components/Common/Loader/Loader';
import { submitForm } from '@lib/hubspot/form';

export interface NewsletterProps {
  node?: DrupalNode;
  title?: string;
  subtitle?: string;
  isModal?: boolean;
}

const newsletterFormId = '8f39b5e3-b689-407d-8770-daa6045e6a0f';

const Newsletter: React.FC<NewsletterProps> = ({title, subtitle, isModal = false}) => {

  const {t} = useTranslation();
  const router: NextRouter = useRouter();
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const newsletterImageComponent: JSX.Element = useMemo(() => (
    <Image src={newsletterImage} alt="Newsletter" style={{width: '100%', height: 'auto', marginTop: 20}}/>
  ), []);

  const reqQuery:string = router?.query?.req?.toString()
  const newsletterSchema: Schema = {
    title: 'Newsletter Form',
    fields: [
      {
        component: componentTypes.TEXT_FIELD,
        name: 'firstname',
        label: t('newsletter.fields.firstname'),
        placeholder: t('newsletter.fields.firstname'),
        isRequired: true,
        initialValue: '',
        FormFieldGridProps: { lg: 12 },
        InputLabelProps: { required: true },
        margin: 'normal',
        variant: "standard",
        validate: [
          { type: validatorTypes.REQUIRED, message: t('newsletter.validation.required') },
          { type: validatorTypes.PATTERN, pattern: /^[a-zA-Z ']+$/i, message: t('newsletter.validation.incorrectFormat')}
        ],
      },
  
      {
        component: componentTypes.TEXT_FIELD,
        name: 'lastname',
        label: t('newsletter.fields.lastname'),
        placeholder: t('newsletter.fields.lastname'),
        isRequired: true,
        initialValue: '',
        FormFieldGridProps: { lg: 12 },
        InputLabelProps: { required: true },
        margin: 'normal',
        variant: "standard",
        validate: [
          { type: validatorTypes.REQUIRED, message: t('newsletter.validation.required') },
          { type: validatorTypes.PATTERN, pattern: /^[a-zA-Z ']+$/i, message: ('newsletter.validation.incorrectFormat')}
        ],
      },
  
      {
        component: componentTypes.TEXT_FIELD,
        name: 'email',
        label: t('newsletter.fields.email'),
        placeholder: t('newsletter.fields.email'),
        isRequired: true,
        initialValue: '',
        FormFieldGridProps: { lg: 12 },
        InputLabelProps: { required: true },
        margin: 'normal',
        variant: "standard",
        validate: [
          { type: validatorTypes.REQUIRED, message: t('newsletter.validation.required') },
          { type: validatorTypes.PATTERN, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: ('newsletter.validation.incorrectFormat') }
        ]
      },
  
      {
        component: componentTypes.TEXT_FIELD,
        name: 'campagna_mrk',
        initialValue: reqQuery ?? '',
        hideField: true,
      },
  
      {
        component: componentTypes.CHECKBOX,
        value: true,
        initialValue: true,
        name: 'scopo_4___newsletter',
        hideField: true,
      },
  
      {
        component: componentTypes.CHECKBOX,
        value: true,
        initialValue: true,
        name: 'consenso_4_newsletter_x_form',
        hideField: true,
      }
    ]
  }

  const handleSubmit = async (values) => {
    
    try {
      setLoader(true);
      const submission = await submitForm( newsletterFormId, values, router );

      if(submission?.status === 200) {
        router.push('/thankyou-NL')
      }

    } catch(e) {
      console.error('error', e)
      setError(true)
    } finally {
      setLoader(false)
    }
  }
  const handleCloseSnackBar: () => void = () => { setError(false) }

  return (
    <Container maxWidth={false} sx={{my: 80}} disableGutters={isModal} id={'newsletter-form'}>
      <Row spacing={20} rowSpacing={60} justifyContent={'center'}>
        <Grid item lg={3}>
          {newsletterImageComponent}
        </Grid>

        <Grid item lg={7}>
          <Box mb={32}>
            <Typography variant='h2' dangerouslySetInnerHTML={{__html: title ?? t('newsletter.title')}} />
            <Typography variant='bodyL' component="div" mt={24} dangerouslySetInnerHTML={{__html: subtitle ?? t('newsletter.subtitle')}} /> 
          </Box>

          {newsletterSchema &&
            <FormRenderer
              schema={newsletterSchema}
              componentMapper={componentMapper}
              FormTemplate={({ formFields }: FormTemplateRenderProps) => <NewsletterFormTemplate formFields={formFields} />}
              onSubmit={handleSubmit}
            />
          }
        </Grid>
      </Row>

      {loader && <Loader active={loader} />}

      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          <AlertTitle>{t('newsletter.error.title')}</AlertTitle>
          {t('newsletter.error.subtitle')}
        </Alert>
      </Snackbar>

    </Container>
  )
}

export default  Newsletter