import { NextRouter, useRouter } from "next/router";
import Box from "@mui/material/Box";
import { FC } from "react";
import React from "react";

const NoLayout: FC<any> = ({ children, node, menus }) => {
  const router: NextRouter = useRouter();

  return (
    <>
      <Box className="NoLayout" component="main">
        {children}
      </Box>
    </>
  );
};

export default NoLayout;
