import { NextRouter } from "next/router";
import axios from "axios";
import { uploadFileToFolder } from "./file";
import { frontendUrl } from "@helper/url";
import { HubSpotFormResponse } from "@model/form.model";
import Cookies from 'js-cookie';

export const getForm = async (formId: string): Promise<HubSpotFormResponse> => {
  try {
    const response = await axios.get(`https://api.hubapi.com/marketing/v3/forms/${formId}`, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${process.env.HUBSPOT_CLIENTID}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching form:', error);
    throw error;
  }
}

export async function submitForm(formId: string, values: Record<string, any>, router: NextRouter) {
  const hubspotutk: string = Cookies.get("hubspotutk") || null;
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/26099279/${formId}`;

  const parseValues = (value) => {
    if (Array.isArray(value)) {
      return value.join(";");
    } else {
      return value;
    }
  };

  const fields = await Promise.all(Object.keys(values).map(async (value) => {
    if (values[value].hasOwnProperty("inputFiles")) {
      const file = values[value];
      const folderPath = value;

      const url = await uploadFileToFolder(file, folderPath);

      return {
        objectTypeId: "0-1",
        name: value,
        value: parseValues(url)
      };

    } else {
      return {
        objectTypeId: "0-1",
        name: value,
        value: parseValues(values[value]),
      };
    }
  }));

  const data = JSON.stringify({
    submittedAt: Date.now(),
    fields: fields,
    context: {
      hutk: hubspotutk,
      pageUri: router.asPath,
      pageName: frontendUrl(router.asPath),
    },
    legalConsentOptions: {
      consent: {
        consentToProcess: true,
        text: "I agree to allow Example Company to store and process my personal data.",
        communications: [
          {
            value: true,
            subscriptionTypeId: 999,
            text: "I agree to receive marketing communications from Example Company.",
          },
        ],
      },
    },
  });

  try {
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    console.error('Error submitting form:', error);
    return error;
  }
}

// TODO: eliminare
export async function sendFormData(formId, formData, router) {
  const hubspotutk: string = Cookies.get("hubspotutk") || null;
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/26099279/${formId}`;

  const parseValues = (value) => {
    if (Array.isArray(value)) {
      return value.join(";");
    } else {
      return value;
    }
  };

  const fields = await Promise.all(Object.keys(formData).map(async (value) => {
    if (formData[value].hasOwnProperty("inputFiles")) {
      const file = formData[value];
      const folderPath = value;

      const url = await uploadFileToFolder(file, folderPath);

      return {
        objectTypeId: "0-1",
        name: value,
        value: parseValues(url)
      };

    }
    if (!formData[value].hasOwnProperty("inputFiles")) {
      return {
        objectTypeId: "0-1",
        name: value,
        value: parseValues(formData[value]),
      };
    }
  }));

  const data = JSON.stringify({
    submittedAt: Date.now(),
    fields: fields,
    context: {
      hutk: hubspotutk,
      pageUri: router.asPath,
      pageName: frontendUrl(router.asPath),
    },
    legalConsentOptions: {
      consent: {
        consentToProcess: true,
        text: "I agree to allow Example Company to store and process my personal data.",
        communications: [
          {
            value: true,
            subscriptionTypeId: 999,
            text: "I agree to receive marketing communications from Example Company.",
          },
        ],
      },
    },
  });

  try {
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}