import Typography from "@mui/material/Typography";
import ArrowOutward from "@mui/icons-material/ArrowOutward";

import { MenuItemPrimary } from "@components/Common/Menu/MenuItemPrimary";
import MenuItemMobile from "@components/Common/Menu/MenuItemMobile";
import Link from "next/link";
import { getUrlAttributes } from "./media";


export const renderMainMenu = (menu) => menu?.map((item) => <MenuItemPrimary key={item.id} item={item} />)

export const renderMainMenuMobile = (menu, close, parentOpen) => menu?.filter(el => el.field_layout !== 'card').map(item => <MenuItemMobile key={item.id} item={item} close={close} parentOpen={parentOpen} />)

export const renderFooterMenu = (menu) => {
  return (

    menu.map(item => {
      const { href, title } = getUrlAttributes(item);
      const isCompanyProfile = title === 'Company Profile';
      const external: boolean = isCompanyProfile || item?.options?.external && !item.url.includes(process.env.NEXT_PUBLIC_BASE_URL);

      return (
        <Typography key={item.id} variant="bodyM" component={external ? 'a' : Link} href={href} title={title} target={external ? '_blank' : '_self'} mb={4}>
          {title} {external && <ArrowOutward />}
        </Typography>
      )
    })
  )
}

export const renderCopyrightMenu = (menu) => menu?.map((item) => {
  const { href, title, target } = getUrlAttributes(item);

  return (
    <Link key={item.id} href={href} target={target} title={title}>
      {item.title}
    </Link>
  )
})