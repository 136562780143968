import { PreviewAlert } from "@components/preview-alert"
import Footer from "@components/Common/Footer/Footer"
import Header from "@components/Common/Header/Header"
import StickyBar from "@components/Common/StickyBar"
import { FC } from "react"
import { TODO } from "@model/model"
import React from "react"


const Layout: FC<TODO> = ({children, menus, node, langData}) => {
  return (
    <>
      <PreviewAlert />
      <Header menus={menus} langData={langData} />
      <main>{children}</main>
      <Footer menus={menus} />
      <StickyBar type={node?.field_stickybar}/>
    </>
  )
}

export default Layout