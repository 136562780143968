import { LangDataProps } from "@helper/langData";
import { DrupalNode } from "next-drupal";

// Variants
import EmptyLayout from "@components/Layout/variant/EmptyLayout";
import LayoutDefault from "@components/Layout/variant/LayoutDefault";
import NoLayout from "./variant/NoLayout";

export interface LayoutProps {
  variant?: 'default' | 'empty' | 'longform' |'NoLayout',
  node?: DrupalNode,
  langData?: LangDataProps[],
  menus?,
  children: React.ReactNode;
}

export const LayoutVariant: React.FC<LayoutProps> = ({variant, children, ...props}) => {
  const layoutType = {
    default: LayoutDefault,
    empty: EmptyLayout,
    NoLayout,
  }

  const Component = layoutType[variant] ||  layoutType['default'];
  return (
    <Component { ...props}>
      {children}
    </Component>
  );
}

export default LayoutVariant